import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Судебные споры | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Основным направлением нашей деятельности в сфере оказания юридических услуг является представление интересов наших клиентов в суде." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/sudebnye-spory'
				name="Судебные споры | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Основным направлением нашей деятельности в сфере оказания юридических услуг является представление интересов наших клиентов в суде."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Судебные споры" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Судебные споры"}
			
				html={

				`

				<p class="TextText">
				Основным направлением нашей деятельности в сфере оказания юридических услуг является представление интересов наших клиентов в суде. Для юридических лиц мы предлагаем представительство в Арбитражном суде, для физических лиц – представительство в гражданских судах (судах общей юрисдикции).
По статистике 50% всех судебных дел - это взыскание задолженности в суде. Судебное взыскание задолженности – является эффективной формой решения споров экономического и имущественного характера во всём цивилизованном мире. Однако взыскание задолженности в суде – это далеко не единственная категория судебных споров. В суде можно защитить свою собственность, оспорить договор или напротив доказать его действительность, решить корпоративный спор и многое другое. Разрешение арбитражных споров как правило происходит в течение 2-3 месяцев (с учётом рассмотрения спора Апелляционной инстанцией 5-6 месяцев).
				</p>

				<p class="TextText">
				Следует особо отметить, что представительство в Арбитражном суде (как и представительство в гражданских судах) будет наиболее результативным и принесет клиенту необходимый эффект, только в том случае если юрист, оказывающий услуги по защите интересов в суде, обладает обширными знаниями в правовой области, постоянно изучает изменения в законодательстве, всегда в курсе судебной практики и умеет применить свои знания в суде. Так же для представления интересов клиента в суде необходимо хорошо понимать психологические аспекты ведения дела в суде, что включает в себя логически грамотное выстраивание линии защиты, а также умение молниеносно ориентироваться по ситуации в ходе процесса, в том числе представлять суду выгодные клиенту контраргументы в противовес аргументам оппонента.
В любой ситуации, для того чтобы суд разрешил Ваше дело в оптимальные сроки и принял необходимое для Вас решение, необходимо сделать правильный выбор, чтобы вашими представителями в суде были юристы являющиеся настоящими профессионалами, способные получить необходимый вам положительный результат.
				</p>

				<p class="TextText">
				Наши юристы обладают всеми вышеперечисленными качествами и имеют обширный успешный опыт представительства в Арбитражном суде по различным категориям и видам дел в судах различных инстанций. В практике наших юристов имеется опыт взыскания задолженности в Арбитражном суде на миллионы рублей. Это утверждение также верно для представительства в гражданских судах, так как наши юристы имеют опыт успешной защиты наших клиентов в Федеральных судах общей юрисдикции (районных суда города Казани).
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					<b>Наименование судебных дел:</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					взыскание задолженности в Арбитражном суде
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					взыскание задолженности в судах общей юрисдикции
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					споры по заключению договоров, о расторжении договоров и оспаривании сделок
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					споры по оспариванию решений и действий (бездействия) государственных органов, органов местного самоуправления и других властных структур
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					признание недействительными ненормативных актов государственных и иных органов
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					споры, связанные с защитой собственности
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					споры по земельным вопросам
				</p>

				<p class="TextText">
					В заключение хочется добавить, что распространённой ошибкой кредиторов является ожидание возврата долга, в ответ на просьбы должника подождать «ещё чуть-чуть». В этом случае существует, как правило, два варианта развития событий и при обоих долг никогда не будет возвращён: должник или окажется обманщиком или банкротом. Именно поэтому судебное взыскание задолженности единственная возможность вернуть свои денежные средства.
				</p>


				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
